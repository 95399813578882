<template>
  <div
    :style="`margin-top: ${
      landing.advanced.global.active_header_inside ||
      landing.advanced.global.active_logo_inside
        ? '20px'
        : '90px'
    };padding:${landing.branding.lining_margin ? '10px' : '0'}`"
  >
    <div :style="liningStyles" style="min-height: 300px;">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
        "
      >
        <div
          v-html="landing.order_form.content[landing.current_lang].emptyCart"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CartPreview",
  props: {
    page: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cartProducts: [],
    };
  },
  computed: {
    ...mapState(["landing"]),
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
    totalPrice() {
      return this.cartProducts.reduce((acc, item) => {
        acc = acc + +item.price * +item.count;
        return acc;
      }, 0);
    },
  },
  methods: {
    decrProduct(product) {
      if (product.count > 1) {
        product.count -= 1;
      }
    },
    deleteProduct(i) {
      this.cartProducts.splice(i, 1);
      localStorage.setItem(
        "cart_" + this.$route.params.id + "_" + this.$route.params.page,
        JSON.stringify(this.cartProducts)
      );
      window.dispatchEvent(
        new CustomEvent("foo-key-localstorage-changed", {
          detail: {
            storage: localStorage.getItem(
              "cart_" + this.$route.params.id + "_" + this.$route.params.page
            ),
          },
        })
      );
    },
    clear() {
      this.cartProducts = [];
      localStorage.setItem(
        "cart_" + this.$route.params.id + "_" + this.$route.params.page,
        JSON.stringify(this.cartProducts)
      );
      window.dispatchEvent(
        new CustomEvent("foo-key-localstorage-changed", {
          detail: {
            storage: localStorage.getItem(
              "cart_" + this.$route.params.id + "_" + this.$route.params.id
            ),
          },
        })
      );
    },
  },
  mounted() {
    if (localStorage.getItem("cart_" + this.$route.params.id)) {
      this.cartProducts = JSON.parse(
        localStorage.getItem("cart_" + this.$route.params.id)
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.cart_buttons {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  cursor: pointer;
}
</style>
